import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'entrance',
    component: () => import('../views/entrance.vue')
  },
  {
    path: '/tech',
    name: 'tech',
    component: () => import('../views/Home.vue'),
    redirect: '/tech/home',
    children: [
      {
        // 金烽科技-首页
        path: 'home',
        name: 'Homes',
        component: () => import('../views/Homes.vue')
      },
      {
        // 金烽科技-技术服务
        path: 'service',
        name: 'digitization',
        component: () => import('../views/digitization.vue')
      },
      {
        // 金烽科技-客户案例
        path: 'case',
        name: 'Cooperation',
        component: () => import('../views/Cooperation.vue')
      },
      {
        // 金烽科技-关于我们
        path: 'about',
        name: 'AboutUS',
        component: () => import('../views/AboutUs.vue')
      },
      {
        // 金烽科技-产品方案
        path: 'project',
        name: 'project',
        component: () => import('../views/project.vue')
      },
      {
        // 金烽科技-产品方案-无人机空域监管平台
        path: 'airmanage',
        name: 'airmanage',
        component: () => import('../views/projectWrj.vue')
      }
    ]
  },
  {
    path: '/intelligent',
    name: 'intelligent',
    component: () => import('../views/intelligentCreation/smarttable.vue'),
    redirect: '/intelligent/home',
    children: [
      {
        // 金烽智造-首页
        path: 'home',
        name: 'smartHome',
        component: () => import('../views/intelligentCreation/smartHome.vue')
      },
      {
        // 金烽智造-产品介绍
        path: 'product',
        name: 'Productntroduction',
        component: () =>
          import('../views/intelligentCreation/Productntroduction.vue')
      },
      {
        // 金烽智造-项目案例
        path: 'case',
        name: 'projectCase',
        component: () => import('../views/intelligentCreation/projectCase.vue')
      },
      {
        // 金烽智造-联系我们
        path: 'about',
        name: 'aboutUSone',
        component: () => import('../views/intelligentCreation/aboutUSone.vue')
      }
    ]
  },
  {
    path: '/beacon',
    name: 'beacon',
    component: () => import('../views/beacon.vue')
  },
  {
    path: '/DigitalTwinning',
    name: 'DigitalTwinning',
    component: () => import('../views/DigitalTwinning.vue')
  },
  {
    path: '/solution',
    name: 'solution',
    component: () => import('../views/solution.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
