<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
var _hmt=_hmt||[];
(function() {
  var hm=document.createElement('script')
  hm.src='https://hm.baidu.com/hm.js?164ed909574883d6498220c5608643a0'
  var s=document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(hm,s)
})()
</script>
<style lang="scss" scoped>
#app {
  height: 100%;
}
</style>
